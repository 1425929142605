@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/helveticaneueltpro-roman.eot');
    src: url('../fonts/helveticaneueltpro-roman.woff') format('woff'),
        url('../fonts/helveticaneueltpro-roman.ttf') format('truetype'),
        url('../fonts/helveticaneueltpro-roman.svg#helveticaneueltpro-roman') format('svg'),
        url('../fonts/helveticaneueltpro-roman.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
} 

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../fonts/HelveticaNeueLTPro-Bd.eot');
    src: url('../fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
         url('../fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype'),
         url('../fonts/HelveticaNeueLTPro-Bd.svg#HelveticaNeueLTPro-Bd') format('svg'),
         url('../fonts/HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueLTPro-Lt';
    src: url('../fonts/HelveticaNeueLTPro-Lt.eot');
    src: url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
         url('../fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
         url('../fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg'),
         url('../fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueLTPro-Th';
    src: url('../fonts/HelveticaNeueLTPro-Th.eot');
    src: url('../fonts/HelveticaNeueLTPro-Th.woff2') format('woff2'),
         url('../fonts/HelveticaNeueLTPro-Th.woff') format('woff'),
         url('../fonts/HelveticaNeueLTPro-Th.ttf') format('truetype'),
         url('../fonts/HelveticaNeueLTPro-Th.svg#HelveticaNeueLTPro-Th') format('svg'),
         url('../fonts/HelveticaNeueLTPro-Th.eot?#iefix') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue LT Pro';
	src: url('../fonts/HelveticaNeueLTPro-Cn.eot');
	src: local('Helvetica Neue LT Pro 57 Condensed'), local('HelveticaNeueLTPro-Cn'),
		url('../fonts/HelveticaNeueLTPro-Cn.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueLTPro-Cn.woff2') format('woff2'),
		url('../fonts/HelveticaNeueLTPro-Cn.woff') format('woff'),
		url('../fonts/HelveticaNeueLTPro-Cn.ttf') format('truetype'),
		url('../fonts/HelveticaNeueLTPro-Cn.svg#HelveticaNeueLTPro-Cn') format('svg');
	font-weight: normal;
	font-style: normal;
} 

$font-helvetica: 'HelveticaNeueLTPro-Th', sans-serif;

   
.mobile-menu {
    display: none
}

#header {
    position: relative;
    line-height: 98px;
    width: 100%;
    .menu {
        display: block;
        list-style-type: none;
        line-height: inherit;
        padding: 0;
        @at-root #{&}__login {
            padding:0 1rem 0 2rem;
            font-size:rem-calc(15);
            background-color: #ed1c24;
            a {
                position: relative;
                span {
                    color:#fff;
                }
                &:before {
                    position: absolute;
                    content: url('../img/ic_login.png');
                    top:8px;
                    left:-10px;
                }
            }
        }
        @at-root #{&}__search {
            position: relative;
            padding:0 1rem 0 2rem;
            font-size:rem-calc(15);
            background-color: #000;
            a {
                position: relative;
                span {
                    color:#fff;
                }
                &:before {
                    position: absolute;
                    content: url('../img/ic_search.png');
                    top:8px;
                    left:-10px;
                }
            }
        }
        li {
            display: inline-block;
            text-align: left;
            a {
                line-height: inherit;
                position: relative;
                text-transform: uppercase;
                font-size: rem-calc(13.96);
                color: #000;
                -webkit-transition: color .25s ease;
                transition: color .25s ease;
                font-weight: 700;
                padding: 0 18px;
                span {
                    display: inline-block;
                    line-height: 1.4;
                    // height: 38px;
                    vertical-align: middle;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    width: 0;
                    background: $primary-color;
                    height: 8px;
                    bottom: 0;
                    -webkit-transition: all .25s ease;
                    transition: all .25s ease;
                }
            }
            &:not(:last-child) {
                // border-right: 1px solid #d7d7d7
            }
        }
    }
    .logo {
        display: inline-block;
        position: relative;
        top: -5px
    }
}

#header .menu li.current-menu-item a:after,
#header .menu li:hover a:after {
    width: calc(100% - 40px);
    left: 20px
}

#header .menu li.current-menu-item a {
    color: #002c73
}



@include breakpoint(medium down) {
// @media screen and (max-width: 63.9375em) {
    #header .mobile-menu-container {
        display:none;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        text-align: right;
        background: #eee;
        border-top: 1px solid #d7d7d7;
        padding: 15px 0;
        z-index: 100;
        .menu {
            line-height: 40px
        }
    }


    #header .mobile-menu-container .menu li {
        display: block
    }

    #header .mobile-menu-container .menu li a {
        display: inline-block!important;
        padding: 0 .9375rem
    }

    #header .mobile-menu-container .menu li.current-menu-item a:after,#header .mobile-menu-container .menu li:hover a:after {
        width: calc(100% - 1.875rem);
        left: .9375rem
    }

    #header .mobile-menu-container .menu li:not(:last-child) {
        border-right: none
    }

    #header .mobile-menu-container .menu li br {
        display: none
    }
}

@include breakpoint(large up) {
// @media print,screen and (min-width: 64em) {
    #header .mobile-menu-container {
        display:block!important;
    }
}




.hamburger {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;
    width: 15px;
    height: 26px;
    z-index: 2;
    margin-top: 5px
}

.hamburger__inner {
    display: block;
    width: 26px;
    height: 4px;
    margin-top: 8px;
    background: #000;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    &:after,
    &:before {
        display: block;
        position: absolute;
        content: "";
        z-index: -1;
        width: 26px;
        height: 4px;
        background: #000;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out
    }
    &:before {
        top: 0;
    }
    &:after {
        top: 16px;
    }
}


.hamburger--open {
    .hamburger__inner {
        background: 0 0
    }
}

.hamburger--open .hamburger__inner::after,
.hamburger--open .hamburger__inner::before {
    top: 8px
}

.hamburger--open .hamburger__inner::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.hamburger--open .hamburger__inner::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

header h1 {
    font-family: 'HelveticaNeueLTPro-Th';
    color:#fff;
    line-height: rem-calc(63.72);
    @include breakpoint(medium down) {
        line-height: initial;
    }
    >span {
        font-family: 'Helvetica Neue';
        font-weight: bold;
    }
}

footer {
    background: url(../img/bg_footer.png) center center no-repeat;
    background-size: cover;
    background-color: #000;
    color:#fff;
    // font-weight: lighter;
    font-family: 'HelveticaNeueLTPro-Th';
    padding:3rem 0;
    // font-family: 'Helvetica Neue LT';

    a {
        color:#fff;
        &:hover,
        &:focus
         {
            color:#fff;
            text-decoration: underline;
        }
    }
    p {
        margin:0;
        // line-height: rem-calc(16);
    }
    .address {
        @include breakpoint(medium down) {
            margin-bottom:2rem;
        }
        @include breakpoint(small down) {
            margin-top:2rem;
            margin-bottom:1rem;
        }


        &.copy {
            border-top: 1px solid #797979;
            margin-top: 1rem;
            padding-top: 2rem;
            // margin-top:1rem;
            ul {
                li {
                    display: inline-block;
                    margin-right:2rem;
                    @include breakpoint(small down) {
                        display: block;
                    }
                }
            }
        }
        span {
            position: relative;
            font-weight: bold;
            &:before {
                position: absolute;
                content: url('../img/ic_poi.png');
                top:0;
                left:-25px;
            }
        }
    }
    .input-group {
        margin-top: 1rem;
        input[type="submit"]
        {
            font-family: 'Helvetica Neue LT Pro';
            background-image: url(../img/ic_line.png);
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: 50px;
        }
    }
}


.ikona {
    @include hamburger();
}
.ikona2 {
    @include background-triangle();
}



input[type="text"]
{
    background: transparent;
    // border: none;
}

.blockHeader {
    p {
        font-size: rem-calc(53.45);
        line-height: rem-calc(53);
        margin:0;
        &:first-of-type {
            font-weight: bold;
            margin-top:1rem;
        }
        &:last-of-type {
            // font-weight: lighter;
            font-family: 'HelveticaNeueLTPro-Th';
            margin-bottom:3rem;
            @include breakpoint(small down) {
                margin-bottom:1rem;
            }
        }

        @include breakpoint(small down) {
            font-size:rem-calc(32);
            line-height: rem-calc(32);
        }
    }
}

#locator {
    margin-top: 4rem;
    padding: 4rem 0;
    position: relative;
    overflow: hidden;
    
    @include breakpoint(small down) {
        margin-top: 2rem;
        padding: 1rem 0;
    }

    .bg {
        background: url(../img/locator-bg.png) 100% 0 no-repeat;
        // background-size: 1524px 380px;
        // background-size: 50% 100%;
        position: absolute;
        height: 380px;
        width:100%;
        z-index: -1;
        top:0;
        right:-10%;
        @include breakpoint(medium down) {
            right: initial;
        }
    }
    .form {
        background: $primary-color;
        padding: 5rem 4rem;
        color:#fff;

        @include breakpoint(small down) {
            padding: 2rem;
        }

        p {
            &:first-of-type {
                font-size:rem-calc(20);
                font-weight: bold;
            }
            &:last-of-type span {
                font-family: 'Helvetica Neue LT Pro';
                font-size:rem-calc(17.54);
                font-weight: normal;
                position: relative;
                &:before {
                    position: absolute;
                    content: url('../img/ic_poi2.png');
                    top:0;
                    left:-25px;
                }
            }
        }
        .input-group {
            margin:3rem 0;
            border:1px solid #fff;
            input[type="text"]
            {
                border:0;
                box-shadow: none;
                text-align: center;
                // color:#fff;
            }
            input[type="submit"]
            {
                background-image: url(../img/ic_search.png);
                background-repeat: no-repeat;
                background-position: center;
                border-left:1px solid #fff;
                margin:0.5rem 0;
                background-color: $primary-color;
                padding: 1rem;
                width: 4rem;
            }
        }
    }
}

#newsletter {
    padding: 4rem 0;
    position: relative;
    overflow: hidden;

    @include breakpoint(small down) {
        padding: 1rem 0;
    }

    .bg {
        background: url(../img/newsletter-bg.png) 0 0 no-repeat;
        // background-size: 1524px 380px;
        // background-size: 50% 100%;
        position: absolute;
        height: 380px;
        width:100%;
        z-index: -1;
        top:0;
        left:-10%;
        @include breakpoint(medium down) {
            left: initial;
        }
    }
    .form {
        background: #000;
        padding: 5rem 4rem 2rem;
        color:#fff;
        background: url(../img/form-bg.png) 0 0 no-repeat;
        background-size: cover;

        @include breakpoint(small down) {
            padding: 2rem;
        }

        p {
            &:first-of-type {
                font-size:rem-calc(20);
                line-height: rem-calc(24.76);
            }
            &:last-of-type span {
                font-size:rem-calc(17.54);
                font-weight: normal;
                position: relative;
                &:before {
                    position: absolute;
                    content: url('../img/ic_poi2.png');
                    top:0;
                    left:-25px;
                }
            }
        }
        .input-group {
            &:first-of-type {
                margin:3rem 0 1rem;
            }
            position: relative;
            input[type="submit"]
            {
                font-family: 'Helvetica Neue LT Pro';
                background-image: url(../img/ic_line.png);
                background-repeat: no-repeat;
                background-position: right center;
                padding-right: 50px;
            }
            .help-block {
                position: absolute;
                bottom:-1rem;
                left:0;
            }
            
        }
        .zgody {
            text-align: left;
        }
        label {
            color:inherit;
            font-weight: normal;
            font-size: rem-calc(11);
            // line-height: rem-calc(8);
            margin-right:1rem;
        }
        input[type="checkbox"]
        {
            margin-bottom:0;
        }
        input[type="text"]
        {
            background: #fff;
        }
    }

    .confirm-text {
        font-size: 11px;
    }
    .button-group {
        margin:0 !important;
    }
    .checkbox {
        margin:5px 0 10px 20px;

        input[type="checkbox"]
        {
            margin-left:-20px;
            display:inline-block;
            vertical-align: top;
        }
        label {
            display:inline-block;
            line-height: 1.5;
        }
    }
    .blockHeader p {
        @include breakpoint(xlarge only) {
            font-size:rem-calc(45);
            line-height: rem-calc(45);
        }
        @include breakpoint(large only) {
            font-size:rem-calc(40);
            line-height: rem-calc(40);
        }
    }
}

#news {
    margin-top: 4rem;
    margin-bottom: 4rem;

    .news {
        >div {
            position: relative;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
        @at-root #{&}__bg {
            background: #f3f3f3;
            margin:auto;
            position: absolute;
            height: 260px;
            width:85%;
            z-index: -1;
            top:0;
            left:6%;
            @include breakpoint(medium down) {
                left: initial;
                width:100%;
            }
        }
        @at-root #{&}__date {
            font-size: rem-calc(15.03);
            color:#727272;
            position: relative;
            padding-left:30px;
            &:before {
                position: absolute;
                content: url('../img/ic_date.png');
                top:0;
                left:0;
            }
        }
        @at-root #{&}__title {
            font-weight: bold;
            color:#1d1c1c;
            line-height: rem-calc(30.34);
            margin-top:1.5rem;
        }
        @at-root #{&}__lead {
            margin-top:2rem;
            margin-bottom:2rem;
            font-size: rem-calc(15.96);
            line-height: rem-calc(27.71);
            color:#727272;
        }

    }   
}


.post {
    margin-bottom: 2rem;
    header {
        background: url(../img/bg_post.png) center center no-repeat;
        background-size: cover;
        padding-top:5rem;
    }
    @include breakpoint(small down) {
        span {
            display: block;
        }
        margin-bottom:3rem;
    }
    @at-root #{&}__meta {
        font-family: 'Helvetica Neue LT Pro';
        background: #eee;
        font-size: rem-calc(15.03);
        padding:2rem;
        margin-top:3rem;
        margin-bottom:-2.5rem;
    }
    @at-root #{&}__date {
        font-size: rem-calc(15.03);
        position: relative;
        margin:1rem;
        padding-left:2rem;
        &:before {
            position: absolute;
            content: url('../img/ic_date.png');
            top:0;
            left:0;
        }
    }
    @at-root #{&}__author {
        font-size: rem-calc(15.03);
        position: relative;
        margin:1rem;
        padding-left:2rem;
        &:before {
            position: absolute;
            content: url('../img/ic_author.png');
            top:0;
            left:0;
        }
    }
    @at-root #{&}__category {
        font-size: rem-calc(15.03);
        position: relative;
        margin:1rem;
        padding-left:2.5rem;
        &:before {
            position: absolute;
            content: url('../img/ic_category.png');
            top:0;
            left:0;
        }
    }
    @at-root #{&}__body {
        margin-top:5rem;
        margin-bottom:3rem;
        h4 {
            font-size:rem-calc(20.96);
            font-weight: bold;
            margin:2rem 0;
        }
        p {
            line-height: rem-calc(27.71);
            color:#727272;
        }
    }
    .red1 {
        color: $primary-color;
        font-weight: bold;
    }
    .sp1 {
        background: url(../img/ic_rim.png) bottom right no-repeat;
        // background-size: cover;
        border:1px solid $primary-color;
        font-weight: bold;
        padding:2rem 16rem 2rem 2rem;
        margin:3rem 0;

        @include breakpoint(medium down) {
            padding-right:11rem;
        }
        @include breakpoint(small down) {
            padding-right:2rem;
            background: initial;
        }
    }
    @at-root #{&}__tags {
        font-weight: bold;
        color:#727272;
        font-size:rem-calc(13.96);
        >span {
            margin-right:1rem;
        }
    }

    
    .related {
        margin-top:3rem;
        margin-bottom:6rem;

        h3 {
            font-family: 'Helvetica Neue';
            font-weight: bold;
            margin-bottom:2rem;
        }
        .post_related {
            padding:2rem 0 2rem 2rem;
            position: relative;

            @include breakpoint(medium down) {
                margin-bottom:3rem;
            }

            h3 {
                font-family: 'HelveticaNeueLTPro-Th';
                font-weight: normal;
                line-height: rem-calc(32);
                span {
                    font-family: 'Helvetica Neue';
                    font-weight: bold;
                }
            }
            color:#fff;
            background: url(../img/bg_related.png) center center no-repeat;
            background-size: cover;
            .cta {
                position: absolute;
                bottom: -1.5rem;
                right:1rem;
            }
        }
    }

}

.poradnik {
    color:#fff;
    header {
        // background: url(../img/bg_poradnik.png) center center no-repeat;
        background: url(../img/bg_post.png) center center no-repeat;
        background-size: cover;
        padding-top:6rem;
        padding-bottom:6rem;
        @include breakpoint(medium down) {
            padding-top:2rem;
            padding-bottom:2rem;
        }
    }
    .post__date,
    .post__category {
        margin-left:0;
        margin-right:2rem;
        a {
            color:#fff;
        }
    }
    h1 {
        margin-top:1rem;
    }
    .promo {
        // width:50%;
    }
}

.category {
    header {
        background: url(../img/bg_post.png) center center no-repeat;
        background-size: cover;
        padding:3rem 0;
    }
}

.dealer-locator {
    header {
        background: url(../img/bg_locator.png) center center no-repeat;
        background-size: cover;
        padding-top:3rem;
        .form {
            margin-top:2rem;
            margin-bottom:-2rem;
            background: $primary-color;
            padding: 1.5rem 4rem;
            color:#fff;
            z-index: 10;
            p {
                &:first-of-type {
                    font-size:rem-calc(20);
                    font-weight: bold;
                }
                &:last-of-type span {
                    font-family: 'Helvetica Neue LT Pro';
                    font-size:rem-calc(17.54);
                    font-weight: normal;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: url('../img/ic_poi2.png');
                        top:0;
                        left:-25px;
                    }
                }
            }
            .input-group {
                margin:1.5rem 0;
                border:1px solid #fff;
                input[type="text"],
                input[type="text"]:focus
                {
                    // font-family: 'HelveticaNeueLTPro-Th';
                    border:0;
                    box-shadow: none;
                    text-align: center;
                    color:#fff;
                    background: transparent;
                }
                input[type="submit"]
                {
                    background-image: url(../img/ic_search.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    border-left:1px solid #fff;
                    margin:0.5rem 0;
                    background-color: $primary-color;
                    padding: 1rem;
                    width: 4rem;
                }
            }
        }
    }
}

.poi {
    font-family: 'Helvetica Neue';
    font-weight: normal;
    font-style: normal;
    font-size: rem-calc(16.29);
    margin: 1.2rem 0 1rem 1.2rem;

    table {
        width:auto;
    }
    table tbody, table tbody tr {
        border:0;
        td {
            padding: 0 1rem 0.3rem 0;
            font-size:95%;
        }
    }
    
    @include breakpoint(small down) {
        margin: 0.5rem 0 0.5rem 0.5rem;
    }

    @at-root #{&}__firststop {
        width: 148px;
        height: 30px;
        background-image: url('../img/ic_firststop2.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 0;
    }
    @at-root #{&}__head {
        font-weight: bold;
        font-size: rem-calc(32.62);
        line-height: rem-calc(30);
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        margin-top:0.5rem;
        
        @include breakpoint(small down) {
            font-size: rem-calc(18);
            line-height: rem-calc(18);
        }

    }
    @at-root #{&}__addr {
        position: relative;
        padding-left: 20px;
        margin-bottom: 0;
        
        @include breakpoint(small down) {
            font-size: rem-calc(14);
            line-height: rem-calc(14);
        }

        &:before {
            position: absolute;
            content: url('../img/ic_poi4.png');
            top:2px;
            left:0;
            @include breakpoint(small down) {
                top:-5px;
            }
        }
    }
    
    @at-root #{&}__open {
        margin-top:1rem;
        font-size:rem-calc(18);
        font-weight: bold;
        color:#3bb311;
        margin-bottom: 0.5rem;
    }
}

.cta {
    @include button;
    // font-family: 'Arial Narrow', Arial, sans-serif;
    font-family: 'Helvetica Neue LT Pro';
    font-weight: normal;
    font-style: normal;
    position: relative;
    font-size: rem-calc(17.54);
    padding-right: 60px;
    margin-bottom:0;
    &:after {
        content: '';
        position: absolute;
        border-top:1px solid #fff;
        top:50%;
        right:-1px;
        width:50px;
    }
    .phone {
        position: relative;
        &:before {
            position: absolute;
            content: url('../img/ic_phone.png');
            top:2px;
            left:0;
        }
    }
}

.cta_phone {
    @include button;
    // font-family: 'Arial Narrow', Arial, sans-serif;
    font-family: 'Helvetica Neue LT Pro';
    font-weight: normal;
    font-style: normal;
    position: relative;
    font-size: rem-calc(17.54);
    padding-right: 60px;
    padding-left: 2.5rem;
    margin-bottom:0;
    &:before {
        position: absolute;
        content: url('../img/ic_phone.png');
        top:13px;
        left:10px;
    }
    &:after {
        content: '';
        position: absolute;
        border-top:1px solid #fff;
        top:50%;
        right:-1px;
        width:42px;
    }
}

.cta_visit {
    @include button;
    // font-family: 'Arial Narrow', Arial, sans-serif;
    font-family: 'Helvetica Neue LT Pro';
    font-weight: normal;
    font-style: normal;
    position: relative;
    font-size: rem-calc(17.54);
    padding-right: 60px;
    padding-left: 1.5rem;
    margin-bottom:0;
    background: #000;
    &:after {
        content: '';
        position: absolute;
        border-top:1px solid #fff;
        top:50%;
        right:-1px;
        width:42px;
    }
    &:hover,
    &:focus {
        background: #303030;
    }
}

.cta_header {
    @include button;
    // font-family: 'Arial Narrow', Arial, sans-serif;
    // background:transparent;
    background:$primary-color;
    font-family: 'Helvetica Neue LT Pro';
    font-weight: normal;
    font-style: normal;
    position: relative;
    font-size: rem-calc(17.54);
    padding-right: 60px;
    margin-top:1rem;
    margin-bottom:0;
    border-color:$primary-color;
    letter-spacing: 1px;

    &:hover {
        background:#a01318;
    }

    &:after {
        content: '';
        position: absolute;
        border-top:1px solid #fff;
        top:50%;
        right:-1px;
        width:50px;
    }
}

.cta_like {
    @extend .cta;
    background: #475993;
}

.cta_share {
    @extend .cta;
    background: #fff;
    border-color:#475993;
    color:#475993;
    &:after {
        border-top:1px solid #475993;
    }
}

#map {
    height: 830px;
    // width: 500px;
}




// #sec3 {
//     #slick {
//         .row {
//             > div {
//                 &:first-of-type {
//                     padding-right: 0;
//                 }
//                 &:last-child {
//                     padding-left: 0;
//                 }
//                 .slider {
//                     background-color: #000;
//                     &.inside {
//                         .slick-slide {
//                             &::after {
//                                 bottom:10%;
//                                 color:#000;
//                                 font-weight: 300;
//                                 right:9%;
//                                 letter-spacing: .4rem;
//                                 opacity: 0;
//                                 -moz-transform: translateX(21px);
//                                 -webkit-transform: translateX(21px);
//                                 -o-transform: translateX(21px);
//                                 -ms-transform: translateX(21px);
//                                 transform: translateX(21px);
//                                 // .trans(@time:.5s);
//                                  -webkit-transition-delay: .5s;
//                                 transition-delay: .5s;
//                                 text-align: right;
//                                 position: absolute;
//                             }
//                             &::before {
//                                 bottom:14%;
//                                 color:#000;
//                                 font-size: 10px;
//                                 font-weight: 300;
//                                 right:9%;
//                                 letter-spacing: 0.4rem;
//                                 opacity: 0;
//                                 -moz-transform: translateX(21px);
//                                 -webkit-transform: translateX(21px);
//                                 -o-transform: translateX(21px);
//                                 -ms-transform: translateX(21px);
//                                 transform: translateX(21px);
//                                 // .trans(@time:.5s);
//                                 -webkit-transition-delay: .5s;
//                                 transition-delay: .5s;
//                                 text-align: right;
//                                 position: absolute;
//                             }
//                             &#s1 {
//                                 &::after {
//                                     content: "EPOXY BEIGE & BROWN";
//                                 }
//                                 &::before {
//                                     content: "BISTRO";
//                                 }
//                             }
//                             &#s2 {
//                                 &::after {
//                                     content: "EPOXY GRAY";
//                                 }
//                                 &::before {
//                                     content: "BIURO";
//                                 }
//                             }
//                             &#s3 {
//                                 &::after {
//                                     content: "EPOXY GREY";
//                                 }
//                                 &::before {
//                                     content: "BANK";
//                                 }
//                             }
//                             &#s4 {
//                                 &::after {
//                                     content: "ONIS";
//                                 }
//                                 &::before {
//                                     content: "POKÓJ";
//                                 }
//                             }
//                             &#s5 {
//                                 &::after {
//                                     content: "FAIR";
//                                 }
//                                 &::before {
//                                     content: "POCZEKALNIA";
//                                 }
//                             }
//                             &#s6 {
//                                 &::after {
//                                     content: "EPOXY BEIGE & BROWN";
//                                 }
//                                 &::before {
//                                     content: "SYPIALNIA";
//                                 }
//                             }
//                             &#s7 {
//                                 &::after {
//                                     content: "EPOXY BEIGE";
//                                 }
//                                 &::before {
//                                     content: "SALON";
//                                 }
//                             }
//                             &#s8 {
//                                 &::after {
//                                     content: "EPOXY BEIGE & BROWN";
//                                 }
//                                 &::before {
//                                     content: "SALON";
//                                 }
//                             }
//                             &#s9 {
//                                 &::after {
//                                     content: "EPOXY GRAY";
//                                 }
//                                 &::before {
//                                     content: "SALON";
//                                 }
//                             }
//                             &#s10 {
//                                 &::after {
//                                     content: "ONIS";
//                                 }
//                                 &::before {
//                                     content: "PRZEDPOKÓJ";
//                                 }
//                             }
//                             &#s11 {
//                                 &::after {
//                                     content: "ONIS";
//                                 }
//                                 &::before {
//                                     content: "GABINET";
//                                 }
//                             }
//                             &.slick-current {
//                                 &::before, &::after {
//                                     opacity: 1;
//                                     -moz-transform: translateX(0px);
//                                     -webkit-transform: translateX(0px);
//                                     -o-transform: translateX(0px);
//                                     -ms-transform: translateX(0px);
//                                     transform: translateX(0px);
//                                 }
//                             }
//                         }
//                     }
//                     &.inside-nav {
//                         height:300px;
//                         .slick-slide {
//                             cursor: pointer;
//                             &:focus {
//                                 outline: none;
//                             }
//                             img {
//                                 height: auto;
//                                 // width: 100%;
//                             }
//                             span{
//                                 color:red;
//                                 font-weight: 300;
//                                 font-size: 12px;
//                                 left:50%;
//                                 letter-spacing: .4rem;
//                                 top:46%;
//                                 // .translate;
//                                 position: absolute;
//                                 z-index: 9;
//                             }
//                             &:hover {
//                                 img {
//                                     opacity: .7;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


#slider {
    height: auto;
    color:#fff;
    .slick-slide {
        // background: url(../img/bg_slide.png) center center no-repeat;
        // background-size: cover;
        background: #1d1c1c;
        &>div>div>div {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

    }
    header {
        // padding-top:8rem;
        h1 {
            padding-top:1rem;
            padding-bottom:1rem;
        }
    }
    .ains {
        position: absolute;
        z-index: -10;
    }
    .sbo {
        // background: yellow;
        height: 100%;
    }
    .slider {
        cursor: initial;
        height: 100%;
        margin-top:0;
    }
    .post__date {
        margin-left:0;
    }
}

.slick-dots {
    position: absolute;
    bottom: 2rem;
    z-index: 100;
    text-align: center;
    left:43%;
    li {
        display: inline-block;
        width: 70px;
        border-bottom:1px solid #fff;
        margin-right:1rem;
        cursor: pointer;
        text-align: left;
        button {
            cursor: pointer;
            color: #fff;
            font-family: 'Helvetica Neue LT Pro';
            font-weight: normal;
            font-style: normal;
            position: relative;
            font-size: rem-calc(18.8);
        }
        &.slick-active {
            border-bottom:4px solid #fff;
            button {
                font-size: rem-calc(28.85);

            }

        }
    }
}


.promo {
    position: relative;
    float: right;
    background: $primary-color;
    display: block;
    margin-bottom:4px;
    img { 
        display: block;
    }
    &:hover img {
        opacity: 0.5;
    }
}


// .promo { position: relative; float: left;background: red; }

.promos {
    >div {
        position: relative;
        height:160px;
        background: url(../img/box1.png) center center no-repeat;
        background-size: cover;
        margin-bottom:5px;
        &:hover {
            background: $primary-color;
            opacity: 0.7;
        }
        h4 {
            position: absolute;
            left:1rem;
            bottom:0;
            // font-family: 'HelveticaNeueLTPro-Th';
            // font-weight: normal;
            line-height: rem-calc(32);
            font-family: 'Helvetica Neue';
            font-weight: bold;
            span {
            }
            a {
                color:#fff;
            }
        }
    }
}


.has-tip {
    border:0;
}
.form-error {
    display: block;
    margin:0;
}

.tooltip {
    max-width: 50%;
    @include breakpoint(medium down) {
        max-width: 70%;
    }
    @include breakpoint(small down) {
        max-width: 90%;
    }
}

.popup,
.popup-success,
.popup-error,
.popup-doubleoptin
 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.8);
        left: 0;
        top: 0;
        z-index: 1;
    }
    .popup_container {
        font-family: 'Helvetica Neue';
        font-weight: bold;
        font-size: rem-calc(30);
        display: inline-block;
        position: relative;
        z-index: 3;
        vertical-align: middle;
        left: 50%;
        top: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: #fff;
        text-align: center;
        border-radius: 14px;
        width:600px;
        // height:528px;
        // background: url(../img/tp.jpg) center center no-repeat;
        // background-size: cover;
        background: #000;
        color:#fff;
        // font: normal 2.2rem 'PraxisCom-Bold-Oblique';
        text-shadow: 3px 3px #000;
        padding:8rem 0;
        -webkit-box-shadow: -2px 4px 27px -9px rgba(237,28,35,1);
        -moz-box-shadow: -2px 4px 27px -9px rgba(237,28,35,1);
        box-shadow: -2px 4px 27px -9px rgba(237,28,35,1);
        border: 1px solid #ed1c24;

        @include breakpoint(medium down) {
            width:500px;
            font-size:rem-calc(26);
            text-shadow: 2px 2px #000;
        }
        @include breakpoint(small down) {
            padding:4rem 0;
            width:300px;
            font-size:rem-calc(18);
            text-shadow: 2px 2px #000;
        }
        &:after {
            content: '+';
            // background: #fff;
            color: #fff;
            z-index: 100;
            font-size: 40px;
            position: absolute;
            top:0;
            right:1rem;
            font-family: 'PraxisCom-Bold';
            transform: rotate(45deg);
        }
    }
}

.cookie-popup {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    // height: 100%;
    z-index: 999;
    text-align: center;
    background: rgba(0,0,0,0.9);
    color:#fff;
    >p {
        opacity: 1;
        margin: rem-calc(10) 0;
        font-size: rem-calc(12);
    }
}

.extranet {

    header {
        background: url(../img/bg_post.png) center center no-repeat;
        background-size: cover;
        padding:4rem 0;
        color:#fff;
        p {
            font-size: rem-calc(20);
            font-weight: 700;
            span {
                position: relative;
                &:before {
                    position: absolute;
                    content: url(../img/ic_msg.png);
                    top: -2px;
                    left: -25px;
                }
            }
        }
    }

    .head1 {
        font-size: rem-calc(24);
        font-weight: 700;
        margin-top: 2rem;
    }
    
    .news {
        margin-top: 1rem;
        margin-bottom: 1rem;
        >div {
            position: relative;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        @at-root #{&}__bg {
            background: #f3f3f3;
            margin:auto;
            position: absolute;
            height: 260px;
            width:85%;
            z-index: -1;
            top:0;
            left:6%;
            @include breakpoint(medium down) {
                left: initial;
                width:100%;
            }
        }
        @at-root #{&}__date {
            font-size: rem-calc(15.03);
            color:#727272;
            position: relative;
            padding-left:30px;
            &:before {
                position: absolute;
                content: url('../img/ic_date.png');
                top:0;
                left:0;
            }
        }
        @at-root #{&}__title {
            font-weight: bold;
            color:#1d1c1c;
            font-size: rem-calc(17.88);
            line-height: rem-calc(16.9);
        }
        @at-root #{&}__lead {
            margin-bottom:2rem;
            font-size: rem-calc(11.51);
            line-height: rem-calc(15.22);
            color:#727272;
        }

        .cta {
            font-size: rem-calc(11.97);
            padding-right: 45px;
            &:after {
                width:35px;
            }
        }
    }   

    .usersettings {
        margin-top: 3rem;
        margin-bottom: 3rem;
        >div {
            // position: relative;
            margin-bottom: 1rem;

        }
    }

    .cta1 {
        background: #f3f3f3;
        display: block;
        padding: 2rem 6rem 2rem 4rem;
        color:#000;
        font-size:rem-calc(24.26);
        font-weight:700;
        position: relative;
        margin-bottom: 1rem;
        img {
            padding-right:1rem;
        }
        &:after {
            position: absolute;
            content: url('../img/ic_ar1.png');
            top:20%;
            right:7%;
            border-left: 1px solid #d2d2d2;
            padding-left:7%;
            height: 60%;
            padding-top:3%;
        }
        &:hover {
            background: darken(#f3f3f3,10%);
        }
    }

    .cta2 {
        background: #f3f3f3;
        display: block;
        padding: 2.4rem 6rem 2rem 2rem;
        color:#000;
        font-size:rem-calc(24.26);
        font-weight:700;
        position: relative;
        margin-bottom: 1.1rem;
        img {
            padding-right:1rem;
        }
        &:after {
            position: absolute;
            content: url('../img/ic_ar1.png');
            top:20%;
            right:7%;
            border-left: 1px solid #d2d2d2;
            padding-left:7%;
            height: 60%;
            padding-top:2%;
        }
        &:hover {
            background: darken(#f3f3f3,10%);
        }
        &.active {
            background: #eb212e;
            color: #fff;
            &:after {
                content: url('../img/ic_ar2.png');
            }
        }
    }

    .cta3 {
        display: inline-block;
        padding: 1rem 3rem;
        margin:2rem;
        background: #eb212e;
        color: #fff;
        font-size:rem-calc(18);
        font-weight:700;
        position: relative;
        border:0;
        img {
            padding-right:1rem;
        }
        &:after {
            position: absolute;
            content: url('../img/ic_ar2.png');
            top:25%;
            right:10%;
        }
        &:hover {
            background: darken(#eb212e,10%);
        }
    }

    .cta4 {
        display: inline-block;
        padding: 1rem 3rem;
        margin-top:2rem;
        background: #dadada;
        color: #000;
        font-size:rem-calc(18);
        font-weight:700;
        position: relative;
        border:0;
        width:70%;
        img {
            padding-right:1rem;
        }

        &:hover {
            background: darken(#dadada,10%);
        }
    }

    .cta5 {
        display: inline-block;
        padding: 0.3rem 1rem;
        background: #eb212e;
        color: #fff;
        font-size:rem-calc(16);
        font-weight:400;
        position: relative;
        border:0;

        &:hover {
            background: darken(#eb212e,10%);
        }
    }

    .table_header {
        background: #eb212e;
        color:#fff;
        font-weight: 700;
        font-size:rem-calc(18);
        text-align: center;
        padding: 1rem 0;
    }

    th {
        text-align: center;
        border-bottom:1px solid #d2d2d2;
    }
    th,
    td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-left:1px solid #d2d2d2;
        border-right:1px solid #d2d2d2;
    }
    table tr:last-child td {
        border-bottom:1px solid #d2d2d2;
    }

    h3.form {
        background: #eb212e;
        color:#fff;
        font-weight: 700;
        padding: 1.5rem 0;
        padding-left:2rem;
        margin-bottom:0;
    }
}

.check_voucher {
    background: #f3f3f3;
    height: 100%;
    border-bottom:1.1rem solid #fff;

    input[type="text"] {
        width:70%;
        height:70px;
        border:1px solid #eb212e;
        // padding:2rem 1rem;
        margin:auto;
        margin-top:2rem;
        font-size:rem-calc(16);
        color:#000;
        text-align: center;
        background: #f3f3f3;
        &:-ms-input-placeholder {
            color:#000;
        }
        &::-webkit-input-placeholder {
            color:#000;
        }
        &:-moz-placeholder {
            color:#000;
        }
        &::-moz-placeholder {
            color:#000;
        }
        &.plain {
            background: #dadada;
            border-color: #dadada;
            font-weight: 700;
            margin-top:0;
        }
        
    }
    
    .voucher_pass {
        background: #eb212e;
        color: #fff;
        font-size:rem-calc(18);
        width:70%;
        padding:1rem 3rem;
        margin:auto;
    }

}

$font-praxis: 'Praxis Com', 'Helvetica Neue', sans-serif;

$red: #ed1c24;
@mixin flex-center($align:center, $justify: center) {
	align-items: $align;
	@include flex;
	justify-content: $justify;
}
@mixin rel {
	position: relative;
}
@mixin block {
	display: block;
}
@mixin abs {
	position: absolute;
}
@mixin transform($string) {
	-webkit-transform: $string;
	-moz-transform: $string;
	-ms-transform: $string;
	-o-transform: $string;
}


h2.promo-header {
    display: flex;
    font-family: $font-helvetica;
    font-size:rem-calc(36);
    // font-style: italic;
    // font-weight: 700;
    flex-direction: column;
    margin: 0 0 24px;
    color:#fff;
    // background-color: rgba($black, .6);
    background-color: #000;
    padding: 15px 10px;
    > span {
        // display: inline-table;
        margin: 0;
        line-height: 1;
        >small {
            font-size:rem-calc(18);
            font-weight: bold;
        }
    }
    .red {
        font-weight: bold;
        color: $red;
    }
    &.lv {
        font-size:rem-calc(30);
        line-height: 1.5;
    }
    @include breakpoint(small down) {
        font-size:rem-calc(24);
    }
}
.promo-prices {
    font-family: 'Helvetica Neue', 'HelveticaNeueLTPro-Th', sans-serif;
    margin:0 1px;
    color:#fff;
    display: flex;
    align-items: stretch; /* Default */
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;

    li {
        background-color: rgba($black, .6);
        @include flex-center;
        flex-direction: column;
        height: 100px;
        @include rel;
        width:30%;
        flex: 0 1 auto;
        
        span {
            line-height: 1.2;
        }
        .price {
            
            font-size:rem-calc(28);
            font-weight: bold;
            @include breakpoint(medium down) {
                font-size:rem-calc(38);
            }
            @include breakpoint(small down) {
                font-size:rem-calc(53);
            }
        }
        .zl {
            
            font-size:rem-calc(28);
            font-weight: bold;
            @include breakpoint(medium down) {
                font-size:rem-calc(24);
            }
            @include breakpoint(small down) {
                font-size:rem-calc(33);
            }
        }
        .tire_size {
            font-family: 'HelveticaNeueLTPro-Th', sans-serif;
            font-size:rem-calc(18);
            @include breakpoint(medium down) {
                font-size:rem-calc(18);
            }
            @include breakpoint(small down) {
                font-size:rem-calc(20);
            }
        }
        &::before {
            background-color: $red;
            bottom:-4px;
            content: "";
            @include block;
            height:8px;
            @include abs;
            left:50%;
            @include transform(translateX(-50%));
            width:97px;
        }
        @include breakpoint(medium down) {
            max-width: 33%;
            margin: 0;
            width: 100%;
            &::before {
                max-width: 50%;
            }
            &:nth-child(2) {
                margin: 0 4px;
            }
        }
        @include breakpoint(small down) {
            max-width: none;
            .price {
                font-size:rem-calc(32);
            }
            .zl {
                font-size:rem-calc(16);
            }
            .tire_size {
                font-size:rem-calc(13);
            }
        }
    }
    &.hu {
        li {
            .price {
                font-size:rem-calc(50);
            }
            .zl {
                font-size:rem-calc(25);
            }
            @include breakpoint(small down) {
                .price {
                    font-size:rem-calc(18);
                }
                .zl {
                    font-size:rem-calc(15);
                }
            }
        }
    }
    &.cz {
        li {
            .price {
                font-size:rem-calc(49);
            }
        }
    }
}

#sec1-tires {
    bottom: 14%;
    @include abs;
    left:45%;
    width:290px;
    @include transform(translateX(-40%));
    @include breakpoint(large down) {
        left:43%;
        z-index: 0;
    }
}